import { PortableText } from '@portabletext/react'
import { animated } from 'react-spring'

import styles from './UppercaseHeading.css'

export function UppercaseHeadingPlainXxl({ title, h = undefined, layoutClassName = undefined }) {
  return (
    <UppercaseHeadingPlainBase
      className={cx(styles.componentPlainXxl, layoutClassName)}
      {... { title, h }}
    />
  )
}

export function UppercaseHeadingXxl({ value, h = undefined, layoutClassName = undefined }) {
  return (
    <UppercaseHeadingBase className={cx(styles.componentXxl, layoutClassName)} {... { value, h }} />
  )
}

export function UppercaseHeadingAccentXxl({ value, h = undefined, layoutClassName = undefined, animation = undefined }) {
  return (
    <UppercaseHeadingBase
      className={cx(styles.componentAccentXxl, styles.accent, layoutClassName)}
      {... { value, h, animation }}
    />
  )
}

export function UppercaseHeadingXl({ value, h = undefined, layoutClassName = undefined, animation = undefined }) {
  return (
    <UppercaseHeadingBase
      className={cx(styles.componentXl, layoutClassName)}
      {... { value, h, animation }}
    />
  )
}

export function UppercaseHeadingXlTitleSubTitle({ title, h = undefined, subtitle, layoutClassName = undefined, animation = undefined }) {
  return (
    <UppercaseHeadingPlainBase
      className={cx(styles.componentXlTitleSubTitle, layoutClassName)}
      title={
        <>
          <SubTitle title={subtitle} />
          <Title {...{ title }} />
        </>
      }
      {...{ h, animation }}
    />
  )
}

export function UppercaseHeadingPlainMd({ title, h = undefined, layoutClassName = undefined }) {
  return (
    <UppercaseHeadingPlainBase
      className={cx(styles.componentPlainMd, layoutClassName)}
      {... { title, h }}
    />
  )
}

export function UppercaseHeadingMd({ value, h = undefined, layoutClassName = undefined }) {
  return (
    <UppercaseHeadingBase className={cx(styles.componentMd, layoutClassName)} {... { value, h }} />
  )
}

function UppercaseHeadingPlainBase({ title, h, className, animation = undefined }) {
  return (
    <Base {...{ h, className, animation }}>
      {title}
    </Base>
  )
}

function UppercaseHeadingBase({ value, h, className, animation = undefined }) {
  return (
    <Base {...{ h, className, animation }}>
      <PortableTextHeading {... { value }} />
    </Base>
  )
}

function Base({ children, h, className, animation }) {
  const tag = h ? `h${h}` : 'p'
  const ElementBase = animation ? animated[tag] : tag

  return (
    <ElementBase className={cx(styles.componentBase, className)} style={animation}>
      {children}
    </ElementBase>
  )
}

function PortableTextHeading({ value }) {
  return (
    <PortableText
      // @ts-ignore
      // eslint-disable-next-line @kaliber/layout-class-name
      className={styles.componentPortableTextHeading}
      components={componentsHeading}
      {...{ value }}
    />
  )
}

/** @type {import('@portabletext/react').PortableTextProps['components']} */
export const componentsHeading = {
  block: {
    normal: ({ children }) => <Title title={children} />,
  },
  marks: {
    script: ({ children }) => <SubTitle title={children} layoutClassName={styles.subtitleLayout} />,
  },
}

function Title({ title }) {
  return <span className={styles.componentTitle}>{title}</span>
}

function SubTitle({ title, layoutClassName = undefined }) {
  return <span className={cx(styles.componentSubTitle, layoutClassName)}>{title}</span>
}
